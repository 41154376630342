import React, { createContext, useState, useEffect } from 'react';
import Toast from '../utils/Toast';
import { auth, db } from '../utils/firebase';
import { getDoc, setDoc, doc } from 'firebase/firestore';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useResolvedPath } from 'react-router-dom';

export const AuthContext = createContext({
    // username: null,
});

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // New state
    const [username, setUsername] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(null);
    // const [jwtToken, setJwtToken] = useState(null);
    const apiURL = 'http://100.24.8.95:3001';


    useEffect(() => {
        setIsLoading(true);

        const jwtToken = localStorage.getItem('jwtToken');

        if (jwtToken) {
            fetch(`${apiURL}/secure/user/verifyToken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': jwtToken,
                },
                body: JSON.stringify({ idToken: jwtToken }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.isTokenValid) {
                        setIsLoggedIn(true);
                        setUsername(data.email);
                        setUserId(data.uid);

                        // If the user is already logged in, query the Firebase database to get user details
                        const userDocRef = doc(db, "users", data.uid);
                        getDoc(userDocRef)
                            .then(docSnapshot => {
                                if (docSnapshot.exists()) {
                                    const userData = docSnapshot.data();
                                    if (userData.status === false) {
                                        throw new Error('Account is deactivated, please contact the bookstore admin: admin@bookstore.ca');
                                    } else {
                                        setIsAdmin(userData.isAdmin === true);
                                    }
                                }
                            })
                            .catch(error => {
                                console.error('Error fetching user data from Firestore:', error);
                                setIsAdmin(false); // Set to non-admin in case of an error
                            });
                    } else {
                        localStorage.removeItem('jwtToken');
                        setIsLoggedIn(false);
                    }
                })
                .catch(error => {
                    console.error('Token verification failed:', error);
                    localStorage.removeItem('jwtToken');
                    setIsLoggedIn(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoggedIn(false);
            setIsLoading(false);
        }
    }, []);

    const googleLogin = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const idToken = await user.getIdToken();
            const uid = user.uid;

            await getDoc(doc(db, "users", user.uid)).then((docSnapshot) => {
                if (!docSnapshot.exists()) {
                    setDoc(doc(db, "users", user.uid), {
                        email: user.email,
                        displayName: user.displayName,
                        isAdmin: false,
                        status: true,
                        createdAt: new Date() // Or use Firebase server timestamp
                    });
                } else {
                    const userData = docSnapshot.data();
                    if (userData.status === false) {
                        throw new Error('Account is deactivated, please contact the bookstore admin: admin@bookstore.ca');
                    } else {
                        if (userData.isAdmin === true) {
                            setIsAdmin(true);
                        } else {
                            setIsAdmin(false)
                        }
                    }

                }
            });
            setUsername(user.email); // Updated this line
            setUserId(uid);
            localStorage.setItem('jwtToken', idToken); // Store the token in local storage
            setIsLoggedIn(true);
            showNotification('Login successfully', 'success');
            // Additional logic here (e.g., store user info, navigate)
        } catch (error) {
            console.error('Error during Google sign in:', error);
            const errorMessage = error.message || 'Account is deactivated, please contact the bookstore admin: admin@bookstore.ca'
            showNotification(errorMessage, 'error');
            throw new Error(errorMessage);
        }
    };


    const login = async (username, password) => {
        try {
            const userCredential = await auth.signInWithEmailAndPassword(username, password);
            const user = userCredential.user;
            const idToken = await user.getIdToken();
            const uid = user.uid;

            // Access Firestore database to get user document
            const userDocRef = doc(db, "users", uid);
            const docSnapshot = await getDoc(userDocRef);

            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                // Check the user's status
                if (userData.status === false) {
                    throw new Error('Account is deactivated, please contact the bookstore admin: admin@bookstore.ca');
                } else {
                    if (userData.isAdmin === true) {
                        setIsAdmin(true)
                    } else {
                        setIsAdmin(false)
                    }
                }
            } else {
                // If the user document does not exist, you can choose to throw an error or create a new user document
                // Here we choose to throw an error
                throw new Error('Please register');
            }

            // Update state and local storage
            localStorage.setItem('jwtToken', idToken);
            setUsername(username);
            setUserId(uid);
            setIsLoggedIn(true);
            showNotification('Login successfully', 'success');
        } catch (error) {
            // Catch and handle errors
            console.error('Login failed:', error);
            const errorMessage = error.message || 'Login error';
            showNotification(errorMessage, 'error');
            throw new Error(errorMessage);
        }
    };

    const logout = async () => {
        try {
            // Sign out the user using Firebase
            await auth.signOut();

            // Remove the JWT token from local storage
            localStorage.removeItem('jwtToken');

            setUserId(null);

            // Update state to indicate that the user has logged out
            setIsLoggedIn(false);

            setIsAdmin(false);

            // Show a successful logout notification
            showNotification('Logged out successfully', 'success');

            // Additional cleanup logic can be added here, such as clearing user data, etc.

        } catch (error) {
            console.error('Logout failed:', error);
            // Show a logout failed notification
            showNotification('Logout failed', 'error');
        }
    };

    const [toastMessage, setToastMessage] = useState('');

    const showNotification = (message, severity = 'info') => {
        setToastMessage({ message, severity });
        // Set logic for automatically closing notifications (optional)
        setTimeout(() => setToastMessage(''), 5000);
    };

    return (
        <AuthContext.Provider value={{ isAdmin, apiURL, username, setUsername, isLoggedIn, isLoading, login, logout, showNotification, googleLogin, userId }}>
            {children}
            <Toast message={toastMessage.message}
                severity={toastMessage.severity} onClose={() => setToastMessage('')} />
        </AuthContext.Provider>
    );
};
